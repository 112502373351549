import { CSSObjectWithoutAccentColor } from "holocene-components/common/ModeOfTransportDD/constants";
import { StylesConfig } from "react-select";

interface ModeOfTransportDDStylesState {
  isSelected?: boolean;
  isFocused?: boolean;
}
export const modeOfTransportDDStyles: StylesConfig = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    marginTop: "1px",
    width: "auto",
  }),
  control: (provided: CSSObjectWithoutAccentColor, state) => ({
    ...provided,
    backgroundColor: "white",
    opacity: state.isDisabled ? 0.5 : 1,
    borderRadius: "8px",
    boxShadow: "none",
    paddingLeft: "16px",
    minWidth: "146px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    paddingBottom: "2px",
  }),
  placeholder: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    color: "#878A8A",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    margin: 0,
  }),
  menuList: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    display: "flex",
  }),
  option: (provided: CSSObjectWithoutAccentColor, state: ModeOfTransportDDStylesState) => ({
    ...provided,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#0055FF",
    padding: "14px",
    border: "1px solid #D7D8D8",
    margin: "14px",
    borderRadius: "4px",
    width: "54px",
    height: "54px",
    borderColor: state.isSelected || state.isFocused ? "#0055FF" : "#D7D8D8",
    backgroundColor: "white !important",
    cursor: "pointer",
  }),
  valueContainer: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    borderRadius: "8px",
    padding: 0,
    display: "flex",
    gap: "2px",
  }),
  dropdownIndicator: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    color: "#0055FF",
  }),
  singleValue: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    color: "#0055FF",
  }),
};

export const modeOfTransportOnlyIconDDStyles: StylesConfig = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    marginTop: "1px",
    width: "auto",
    right: "0",
  }),
  control: (provided: CSSObjectWithoutAccentColor, state) => ({
    ...provided,
    backgroundColor: "white",
    opacity: state.isDisabled ? 0.5 : 1,
    boxShadow: "none",
    paddingLeft: "16px",
    maxWidth: "80px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2px",
    paddingBottom: "2px",
    border: "none",
    borderRadius: "0",
  }),
  placeholder: modeOfTransportDDStyles.placeholder,
  menuList: modeOfTransportDDStyles.menuList,
  option: modeOfTransportDDStyles.option,
  valueContainer: modeOfTransportDDStyles.valueContainer,
  dropdownIndicator: modeOfTransportDDStyles.dropdownIndicator,
  singleValue: modeOfTransportDDStyles.singleValue,
};

export enum ModeOfTransport {
  Sea = 4,
  Land = 14,
  Air = 5,
}

export const limitedSpaceStyles: StylesConfig = {
  menu: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    marginTop: "1px",
    padding: "12px",
  }),
  menuList: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
  }),
  option: (provided: CSSObjectWithoutAccentColor, state: ModeOfTransportDDStylesState) => ({
    ...provided,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#0055FF",
    border: "1px solid #D7D8D8",
    borderRadius: "4px",
    width: "32px",
    height: "32px",
    padding: "0px",
    borderColor: state.isSelected || state.isFocused ? "#0055FF" : "#D7D8D8",
    backgroundColor: "white !important",
    cursor: "pointer",
  }),
};

export const errorStyles: StylesConfig = {
  control: (provided: CSSObjectWithoutAccentColor) => ({
    ...provided,
    borderColor: "red",
    borderRadius: "8px",
  }),
};
