/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/display-name */
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CaretDown, Check, MagnifyingGlass, Minus, X } from "phosphor-react";
import { compareArrays } from "holocene-utils/helpers";
import ReactTooltip from "react-tooltip";
import CustomTooltip from "../CustomTooltip";

const DEFAULT_FLAG_IMG =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAACWAQMAAAASWn1AAAAABlBMVEX///8AAABVwtN+AAAAM0lEQVRYw+3YMREAMAjAQOq80uqsKICZOz7rO0j8pjtVXxQdSimllFJKKaWUbtSZB6PTBHS/FNRpgw12AAAAAElFTkSuQmCC";

const onErrorClosure = (alphaCode: string) => (e: any) => {
  const img = e.target as HTMLImageElement;
  const fallbackSrc = `/country-flags/${alphaCode}.svg`;
  if (img.src !== DEFAULT_FLAG_IMG) {
    if (!img.src?.includes(fallbackSrc)) {
      img.src = fallbackSrc;
    } else {
      img.src = DEFAULT_FLAG_IMG;
    }
  }
};

export interface SelectMenuOption {
  value: string | number;
  title: string;
  alphaCode?: string;
}

export interface CountrySelectMenuOption {
  value: string | number;
  label: string;
  alphaCode?: string;
}

export interface MultipleCountrySelectorProps {
  id?: string;
  onChange: (value: CountrySelectMenuOption[]) => void;
  selectedValues: CountrySelectMenuOption[];
  disabled?: boolean;
  label?: string;
  countries: CountrySelectMenuOption[];
  containerStyles?: string;
  dropboxHeight?: string;
  disabledOptions?: (string | number)[];
  error?: string;
  placeholder?: string;
  onMenuClose?: (isChanged?: boolean) => void;
  blend?: boolean;
  menuStyle?: React.CSSProperties;
  showAll?: boolean;
  showCount?: boolean;
}

export const MultipleCountrySelector = ({
  id,
  selectedValues,
  disabledOptions = [],
  disabled,
  countries,
  label,
  onChange,
  blend,
  placeholder,
  menuStyle,
  showCount = false,
  ...props
}: MultipleCountrySelectorProps) => {
  const { onMenuClose, showAll } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const beforeOpenValueRef = useRef(selectedValues);
  const [sortedCountryOptions, setSortedCountryOptions] = useState(countries);

  useEffect(() => {
    setSortedCountryOptions([
      ...selectedValues,
      ...countries.filter((opt) => !selectedValues.some((entry) => entry.value === opt.value)),
    ]);
  }, [isOpen, countries]);

  const onToggle = useCallback(() => {
    setQuery("");
    if (isOpen) {
      const isChanged = !compareArrays(beforeOpenValueRef.current, selectedValues);
      onMenuClose?.(isChanged);
    } else {
      beforeOpenValueRef.current = selectedValues;
    }
    setIsOpen(!isOpen);
  }, [isOpen, onMenuClose, selectedValues]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && isOpen) {
        onToggle();
        setQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onToggle, ref]);

  const [query, setQuery] = useState("");

  const handleSelect = (value: CountrySelectMenuOption, isChecked: boolean) => {
    if (value.value === "all") {
      if (isChecked) {
        onChange([...countries]);
      } else {
        onChange([]);
      }
    } else {
      if (isChecked) {
        onChange([...(selectedValues || []), value]);
      } else {
        onChange((selectedValues || []).filter((opt) => opt.value !== value.value));
      }
    }
  };

  return (
    <div className={"relative " + (props.containerStyles || "")}>
      {label && (
        <label htmlFor={id} className="block text-xs text-secondary-text font-semibold mb-1">
          {label}
        </label>
      )}
      <div ref={ref}>
        <button
          type="button"
          role="combobox"
          className={`bg-white relative ${
            disabled && !blend ? "opacity-50" : ""
          } w-full text-left cursor-default focus:outline-none ${
            blend
              ? ""
              : "border border-strokes rounded-md pl-3 pr-2 py-2 focus:border-holocene-blue"
          } focus:ring-0 sm:text-sm`}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={onToggle}
          disabled={disabled}
        >
          <div className="flex gap-2 items-center justify-between gap-y-1">
            {selectedValues && selectedValues.length > 0 ? (
              selectedValues.length === countries.length ? (
                <label className="text-secondary-text">All</label>
              ) : showCount ? (
                <label
                  data-tip
                  data-for={`selectedCountries-${id}`}
                  className="text-secondary-text text-holocene-blue"
                >
                  {selectedValues.length}
                </label>
              ) : (
                <div className="flex flex-wrap gap-2 gap-y-1">
                  {selectedValues?.map((opt, index) => (
                    <React.Fragment key={opt.value}>
                      {opt.alphaCode ? (
                        <img
                          alt={`${opt.alphaCode}`}
                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${opt.alphaCode}.svg`}
                          className={"inline h-5 rounded-sm"}
                          onError={onErrorClosure(opt.alphaCode)}
                        />
                      ) : (
                        <span className="truncate">
                          {opt.label}
                          {index < selectedValues.length - 1 && <>,&nbsp;</>}
                        </span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )
            ) : (
              <label className="text-secondary-text">{placeholder || "Select"}</label>
            )}
            {blend && disabled ? null : (
              <span>
                <CaretDown size={16} className="text-holocene-blue" />
              </span>
            )}
          </div>
        </button>

        {props.error && <div className="text-red-600 text-xs mt-1">{props.error}</div>}

        {isOpen && (
          <ul
            className="absolute z-40 w-full bg-white shadow-lg max-h-80 rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm min-w-[200px]"
            tabIndex={-1}
            role="listbox"
            aria-labelledby="listbox-label"
            style={menuStyle}
            aria-activedescendant="listbox-option-3"
          >
            <li className="text-primary-text cursor-default select-none relative p-4 pb-1">
              <div className="relative">
                <input
                  autoFocus
                  name="search"
                  autoComplete={"off"}
                  className="rounded text-sm w-full text-primary-text border-strokes !focus:outline-none focus:border-holocene-blue"
                  placeholder={"Search"}
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                />
                {query === "" ? (
                  <MagnifyingGlass
                    size={18}
                    weight="bold"
                    color="#0055FF"
                    className="absolute right-2 top-2"
                  />
                ) : (
                  <X
                    size={18}
                    weight="bold"
                    color="#0055FF"
                    className="absolute right-2 top-2 cursor-pointer"
                    onClick={() => setQuery("")}
                  />
                )}
              </div>
            </li>

            <div
              className={`${
                props.dropboxHeight ? props.dropboxHeight : "max-h-64"
              } scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll`}
            >
              {showAll && !query ? (
                <>
                  <li
                    key="all"
                    className="text-primary-text cursor-default select-none relative py-2 px-4 flex items-center hover:bg-ice-blue-3 transition"
                    id="listbox-option-0"
                    role="option"
                    onClick={() => {
                      if (disabledOptions.includes("all")) {
                        return;
                      }
                      handleSelect(
                        { value: "all", label: "All", alphaCode: "all" },
                        selectedValues.length !== countries.length
                      );
                    }}
                  >
                    <div className="relative">
                      <input
                        type="checkbox"
                        className="rounded text-holocene-blue border-primary-border focus:ring-0 focus:ring-transparent h-6 w-6 cursor-pointer checked:bg-none mr-3"
                        checked={selectedValues.length === countries.length}
                        onChange={() => {}}
                      />
                      {selectedValues.length === countries.length ? (
                        <Check
                          className="absolute text-white top-[3px] left-0.5"
                          weight="regular"
                          size={20}
                        />
                      ) : selectedValues.length > 0 ? (
                        <Minus
                          className="absolute text-holocene-blue top-[3px] left-0.5"
                          weight="regular"
                          size={20}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <span className="font-normal truncate">All</span>
                  </li>
                </>
              ) : (
                <></>
              )}
              {sortedCountryOptions.filter((country) =>
                country.label.toLowerCase().startsWith(query.toLowerCase())
              ).length === 0 ? (
                <li className="text-primary-text cursor-default select-none relative py-2 px-4">
                  No countries found
                </li>
              ) : (
                sortedCountryOptions
                  .filter((country) => country.label.toLowerCase().startsWith(query.toLowerCase()))
                  .map((option) => {
                    const { value, label, alphaCode } = option;
                    const checked = Boolean(selectedValues?.find((opt) => opt.value === value));
                    let className =
                      "text-primary-text cursor-default select-none relative py-2 px-4 flex items-center hover:bg-ice-blue-3 transition";
                    if (disabledOptions.includes(value)) {
                      className = `${className} disabled pointer-none opacity-30`;
                    }
                    return (
                      <li
                        key={value}
                        className={className}
                        id="listbox-option-0"
                        role="option"
                        onClick={() => {
                          if (disabledOptions.includes(value)) {
                            return;
                          }
                          handleSelect(option, !checked);
                        }}
                      >
                        <div className="relative">
                          <input
                            type="checkbox"
                            className="rounded text-holocene-blue border-primary-border focus:ring-0 focus:ring-transparent h-6 w-6 cursor-pointer checked:bg-none mr-3"
                            checked={checked}
                            onChange={() => {}}
                          />
                          {checked && (
                            <Check
                              className="absolute text-white top-[3px] left-0.5"
                              weight="regular"
                              size={20}
                            />
                          )}
                        </div>
                        {alphaCode && (
                          <img
                            alt={`${value}`}
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${alphaCode}.svg`}
                            className={"inline mr-2 h-4 rounded-sm"}
                            onError={onErrorClosure(alphaCode)}
                          />
                        )}
                        <span className="font-normal truncate">{label}</span>
                      </li>
                    );
                  })
              )}
            </div>
          </ul>
        )}
      </div>
      <CustomTooltip id={`selectedCountries-${id}`} place="bottom">
        <ol className="list-decimal !mx-4">
          {selectedValues
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((value) => (
              <li key={value.value}>{value.label}</li>
            ))}
        </ol>
      </CustomTooltip>
    </div>
  );
};

export interface CountrySelectorProps {
  labelClassName?: string;
  id: string;
  onChange: (value: string | number | null) => void;
  selectedValue?: SelectMenuOption | null;
  disabled?: boolean;
  label?: string;
  countries: SelectMenuOption[];
  containerStyles?: string;
  buttonStyles?: string;
  dropboxHeight?: string;
  optionDisabled?: string | string[] | number | number[] | undefined;
  flagKey?: string;
  error?: string;
  hasError?: boolean;
  isClearable?: boolean;
  variant?: "underlined" | "contained" | "borderless";
  onMenuClose?: () => void;
}

export const CountrySelector = React.forwardRef<HTMLDivElement, CountrySelectorProps>(
  (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback(() => {
      if (isOpen && props.onMenuClose) {
        props.onMenuClose();
      }
      setIsOpen(!isOpen);
    }, [isOpen]);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const mutableRef = (ref || containerRef) as MutableRefObject<HTMLDivElement | null>;

      const handleClickOutside = (event: any) => {
        if (mutableRef.current && !mutableRef.current.contains(event.target) && isOpen) {
          onToggle();
          setQuery("");
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen, onToggle, props, ref]);

    const [query, setQuery] = useState("");
    const flagKey = props.flagKey || "value";

    const variantStyle = useMemo(() => {
      let variantClassName = "bg-white border border-strokes rounded-md focus:border-holocene-blue";

      if (props.variant === "underlined") {
        variantClassName =
          "bg-white border-b border-holocene-blue rounded-none text-holocene-blue uppercase";
      } else if (props.variant === "borderless") {
        variantClassName = "border-0 bg-none";
      }

      return props.hasError
        ? "border border-danger focus:border-danger rounded-md"
        : variantClassName;
    }, [props.variant, props.hasError]);

    return (
      <div ref={containerRef} className={"relative " + (props.containerStyles || "")}>
        {props.label && (
          <label
            htmlFor={props.id}
            className={`block text-xs text-secondary-text font-semibold mb-1 ${props.labelClassName}`}
          >
            {props.label}
          </label>
        )}
        <div ref={ref}>
          <button
            role="combobox"
            type="button"
            className={`relative ${
              props.disabled ? "opacity-50" : ""
            } w-full pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-0 sm:text-sm ${
              props.buttonStyles || ""
            } ${variantStyle}`}
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            onClick={onToggle}
            disabled={props.disabled}
          >
            {props.selectedValue?.value ? (
              <span className="truncate flex items-center">
                <img
                  alt={`${props.selectedValue.value}`}
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${
                    props.selectedValue[flagKey as keyof SelectMenuOption]
                  }.svg`}
                  className={"inline mr-2 h-4 rounded-sm"}
                  onError={onErrorClosure(
                    props.selectedValue[flagKey as keyof SelectMenuOption] as string
                  )}
                />
                {props.selectedValue.title}
              </span>
            ) : (
              <label
                className={
                  props.variant === "underlined"
                    ? "text-holocene-blue uppercase"
                    : "text-secondary-text"
                }
              >
                Select
              </label>
            )}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 gap-1 text-holocene-blue">
              {props.isClearable && props.selectedValue ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onChange(null);
                  }}
                >
                  <X size={16} />
                </div>
              ) : (
                <CaretDown size={16} color="#0055FF" />
              )}
            </span>
          </button>
          {props.error && <div className="text-red-600 text-xs mt-1">{props.error}</div>}

          {isOpen && (
            <ul
              className="absolute z-10 w-full bg-white shadow-lg max-h-80 rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <div className="sticky top-0 z-10 bg-white">
                <li className="text-primary-text cursor-default select-none relative p-4 pb-1">
                  <div className="relative">
                    <input
                      autoFocus
                      name="search"
                      autoComplete={"off"}
                      className="rounded text-sm w-full text-primary-text border-strokes !focus:outline-none focus:border-holocene-blue"
                      placeholder={"Search"}
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                    />
                    {query === "" ? (
                      <MagnifyingGlass
                        size={18}
                        weight="bold"
                        color="#0055FF"
                        className="absolute right-2 top-2"
                      />
                    ) : (
                      <X
                        size={18}
                        weight="bold"
                        color="#0055FF"
                        className="absolute right-2 top-2 cursor-pointer"
                        onClick={() => setQuery("")}
                      />
                    )}
                  </div>
                </li>
              </div>

              <div
                className={`${
                  props.dropboxHeight ? props.dropboxHeight : "max-h-64"
                } scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll`}
              >
                {props.countries.filter((country) =>
                  country.title.toLowerCase().startsWith(query.toLowerCase())
                ).length === 0 ? (
                  <li className="text-primary-text cursor-default select-none relative py-2 px-4">
                    No countries found
                  </li>
                ) : (
                  props.countries
                    .filter((country) =>
                      country.title.toLowerCase().startsWith(query.toLowerCase())
                    )
                    .map((value, index) => {
                      const isDisabled =
                        (!Array.isArray(props.optionDisabled) &&
                          value.value === props.optionDisabled) ||
                        (Array.isArray(props.optionDisabled) &&
                          props.optionDisabled.indexOf(value.value as never) !== -1);

                      return (
                        <li
                          key={`${props.id}-${index}`}
                          className={`text-primary-text cursor-default select-none relative py-2 px-4 flex items-center hover:bg-ice-blue-3 transition ${
                            isDisabled ? "disabled pointer-none opacity-30" : ""
                          }`}
                          id="listbox-option-0"
                          role="option"
                          onClick={() => {
                            if (isDisabled) {
                              return;
                            }
                            props.onChange(value.value);
                            setQuery("");
                            onToggle();
                          }}
                        >
                          <img
                            alt={`${value.value}`}
                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${
                              value[flagKey as keyof SelectMenuOption]
                            }.svg`}
                            className={"inline mr-2 h-4 rounded-sm"}
                            onError={onErrorClosure(
                              value[flagKey as keyof SelectMenuOption] as string
                            )}
                          />

                          <span className="font-normal truncate">{value.title}</span>
                          {props.selectedValue && value.value === props.selectedValue.value ? (
                            <span
                              className="text-primary-button-blue absolute inset-y-0 right-0 flex items-center"
                              data-testid="selected-check"
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          ) : null}
                        </li>
                      );
                    })
                )}
              </div>
            </ul>
          )}
        </div>
      </div>
    );
  }
);
