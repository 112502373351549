import apiInstance from "holocene-services/axios";
import { GetNylasMailsResponse, NylasMailMessage } from "holocene-services/nylas.service/types";
import {
  createQueryStringFromFlatObject,
  getMimeTypeFromArrayBuffer,
} from "holocene-utils/common.utils";
import {
  IDeliveryDetails,
  ICreateDeliveryReq,
  DocumentTypeEnum,
  UploadDeliveryDocumentPayload,
  IDeliveryUpdate,
  DeliveryQuantity,
  DeliveryEquipmentLoadPlan,
  IDeliveryListingSearchResponse,
  GetPortsForCountryReponse,
  GetSailingTripsPayload,
  GetSailingTripsResponse,
  PostSelectSailingTripPayload,
  UploadGeneratedDeliveryDocumentPayload,
  DeliverySectionType,
  GetDeliveryCompletionResponse,
  IDispatchedDeliveryListingSearchResponse,
  DispatchedDeliveryFilterParams,
  DeliveryTrackingDetails,
  SealineCarriers,
  IDeliveryInfoFromDocument,
  BookTransportPreRequisites,
  IGetConsolidateDeliveriesRequest,
  IPostConsolidateDeliveryRequest,
  DeliveryTradePolicy,
  UpdateCarrierResponse,
  IGetConsolidateDeliveriesResponseItem,
  IIncomingOrdersListingSearchResponse,
  SalesIncomingOrderProduct,
  IIncomingOrderUpdate,
  IIncomingOrderProductUpdate,
  UploadSalesOrderDocumentPayload,
  IDeliveryListingFlatResponse,
  IISalesOrderDetailsUpdate,
  ILSPTransportCost,
} from "./types";
import { authHeader } from "holocene-services/auth-header";
import { BOOKING_TYPE } from "holocene-components/delivery/BookingForms/constants";
import { UserTabPayload } from "holocene-services/user.service/types";
import {
  ISalesOrder,
  SalesDocument,
  SalesOrderDetailsDocuments,
  SalesOrderDocument,
  SalesOrderLinkedPurchase,
} from "holocene-services/sales-orders.services/types";

class DeliveryService {
  async createDelivery(data: ICreateDeliveryReq) {
    const response = await apiInstance.post(`/delivery/create/manual`, data);
    return response.data;
  }
  createDeliveryAutomatic(document: File) {
    const formData = new FormData();
    formData.append("document", document);

    return apiInstance.post(`/delivery/create/automatic`, formData);
  }

  getTrackingDetails(id: string | number) {
    return apiInstance
      .get(`/transit-tracking/${id}`)
      .then((res) =>
        res.data.result
          ? ({ ...res.data.result, kind: res.data.kind } as DeliveryTrackingDetails)
          : null
      );
  }

  getAllCouriers() {
    return apiInstance.get(`/aftership/couriers`).then((res) => res.data);
  }

  updateTransitTracking(id: number, slug: string) {
    return apiInstance.patch(`/transit-tracking/${id}`, { slug: slug });
  }

  async updateCarrierForTracking(id: string, updatedSlug: string) {
    const body = {
      id: id,
      slug: updatedSlug,
    };
    return apiInstance
      .put(`/aftership/couriers`, body)
      .then((res) => res.data as UpdateCarrierResponse);
  }

  getSealineCarriers() {
    return apiInstance
      .get(`/logistics/liners`)
      .then((res) => (res.data ? (res.data as SealineCarriers[]) : null));
  }

  async updateSealineCarrier(id: number, payload: { scac: string; scacName: string }) {
    const response = await apiInstance.patch(`logistics/${id}/sealine`, payload);
    return response.data;
  }

  getDeliveryDetails(id: string | number) {
    return apiInstance.get(`/delivery/${id}`).then((res) => res.data as IDeliveryDetails);
  }

  deleteDelivery(id: string | number) {
    return apiInstance.delete(`/delivery/${id}`);
  }

  getInQueueDeliveryListingNew(body: UserTabPayload) {
    return apiInstance
      .post(`/delivery/search/in-queue/new`, body)
      .then((res) => res.data as IDeliveryListingSearchResponse);
  }

  getInQueueDeliveryListingFlat(body: UserTabPayload) {
    return apiInstance
      .post(`/delivery/search/in-queue/flat`, body)
      .then((res) => res.data as IDeliveryListingFlatResponse);
  }

  getDispatchedDeliveryListingFlat(body: UserTabPayload) {
    return apiInstance
      .post(`/delivery/search/dispatched/flat`, body)
      .then((res) => res.data as IDeliveryListingFlatResponse);
  }

  getDispatchedDeliveryListingNew(body: UserTabPayload) {
    return apiInstance
      .post(`/delivery/search/dispatched/new`, body)
      .then((res) => res.data as IDeliveryListingSearchResponse);
  }

  getDispatchedDeliveryListing(params: DispatchedDeliveryFilterParams) {
    return apiInstance
      .get(`/delivery/search/dispatched`, { params })
      .then((res) => res.data as IDispatchedDeliveryListingSearchResponse);
  }

  getIncomingOrdersListing(body: UserTabPayload) {
    return apiInstance
      .post(`/incoming-orders/`, body)
      .then((res) => res.data as IIncomingOrdersListingSearchResponse);
  }

  async updateIncomingOrderProduct(orderId: number, body: IIncomingOrderProductUpdate) {
    return apiInstance.patch(`/incoming-orders/${orderId}/products`, body);
  }

  async createIncomingOrderProduct(orderId: number, body: IIncomingOrderProductUpdate) {
    return apiInstance.post(`/incoming-orders/${orderId}/products`, body);
  }

  async deleteIncomingOrderProduct(orderId: number, productId: number) {
    return apiInstance.delete(`/incoming-orders/${orderId}/products/${productId}`);
  }

  async deleteSalesOrder(orderId: number) {
    return apiInstance.delete(`/order/${orderId}`);
  }

  async deleteIncomingOrder(id: number) {
    return await apiInstance.delete(`/incoming-orders/${id}`);
  }

  async updateIncomingOrder(orderId: number, body: IIncomingOrderUpdate) {
    return await apiInstance.patch(`/incoming-orders/${orderId}`, body);
  }

  getIncomingOrderProducts(orderId: number) {
    return apiInstance
      .get(`/incoming-orders/${orderId}/products`)
      .then((res) => res.data as SalesIncomingOrderProduct[]);
  }

  getIncomingOrderEmailData(orderId: number) {
    return apiInstance
      .get(`/incoming-orders/${orderId}/email-message`)
      .then((res) => res.data as { data: { data: NylasMailMessage } });
  }

  getSalesOrderBySalesOrderId(salesOrderId: number) {
    return apiInstance.get(`/order/${salesOrderId}`).then((res) => res.data as ISalesOrder);
  }

  getSalesOrderLinkedPurchase(orderId: number) {
    return apiInstance
      .get(`/order/${orderId}/linked-purchase`)
      .then((res) => res.data as SalesOrderLinkedPurchase);
  }

  uploadDeliveryDocument(payload: UploadDeliveryDocumentPayload) {
    const formData = new FormData();
    formData.append("document", payload.document);
    return apiInstance
      .post(`/delivery/${payload.deliveryId}/upload`, formData)
      .then((res) => res.data as { requestId: string });
  }

  uploadSalesOrderDocument(payload: UploadSalesOrderDocumentPayload) {
    const formData = new FormData();
    formData.append("document", payload.document);
    return apiInstance
      .post(`/order/${payload.salesOrderId}/upload`, formData)
      .then((res) => res.data as SalesOrderDocument);
  }

  getSalesOrderDetailsDocuments(salesOrderId: number) {
    return apiInstance
      .get(`/order/${salesOrderId}/documents`)
      .then((res) => res.data as SalesOrderDetailsDocuments);
  }

  uploadConsolidatedDeliveryDocument(payload: UploadDeliveryDocumentPayload) {
    const formData = new FormData();
    formData.append("document", payload.document);
    return apiInstance
      .post(`/consolidated-delivery/${payload.deliveryId}/upload`, formData)
      .then((res) => res.data as { requestId: string });
  }

  assignDocToConsolidatedDelivery(deliveryId: number, data: any) {
    return apiInstance
      .post(`/consolidated-delivery/${deliveryId}/upload-from-temporary`, {
        consolidatedTemporaryDocumentId: data.salesConsolidatedTemporaryDocumentId,
      })
      .then((res) => res.data as { requestId: string });
  }

  uploadGeneratedDeliveryDocument(payload: UploadGeneratedDeliveryDocumentPayload) {
    const formData = new FormData();
    formData.append("document", payload.document);
    formData.append("documentType", payload.documentType);
    if (payload.isDraft) {
      formData.append("isDraft", "true");
    }
    if (payload.templateValues) {
      formData.append("templateValues", JSON.stringify(payload.templateValues));
    }
    if (payload.label) {
      formData.append("label", payload.label);
    }
    if (payload.generateActionableId) {
      formData.append("generateActionableId", payload.generateActionableId.toString());
    }
    return apiInstance.post(`/delivery/${payload.deliveryId}/upload-manually`, formData);
  }

  updateGeneratedDeliveryDocument(payload: {
    deliveryId: number;
    documentId: number;
    document: File;
    templateValues?: any;
    isDraft?: boolean;
  }) {
    const formData = new FormData();
    formData.append("document", payload.document);
    if (payload.isDraft) {
      formData.append("isDraft", "true");
    }
    if (payload.templateValues) {
      formData.append("templateValues", JSON.stringify(payload.templateValues));
    }
    return apiInstance.patch(
      `/delivery/${payload.deliveryId}/update-document/${payload.documentId}`,
      formData
    );
  }

  async updateDelivery(id: number, data: Partial<IDeliveryUpdate>) {
    const response = await apiInstance.patch(`/delivery/${id}/sales-delivery-data`, data);
    return response.data;
  }

  async updateConsolidatedDelivery(id: number, data: Partial<IDeliveryUpdate>) {
    const response = await apiInstance.patch(
      `/consolidated-delivery/${id}/sales-delivery-data`,
      data
    );
    return response.data;
  }

  async updateConsolidatedChildDelivery(id: number, data: Partial<IDeliveryUpdate>) {
    const response = await apiInstance.patch(
      `/consolidated-delivery/item/${id}/sales-delivery-data`,
      data
    );
    return response.data;
  }

  deleteDeliveryDocument(docId: string | number) {
    return apiInstance.delete(`/document/${docId}`);
  }

  reclassifyDeliveryDocument(docId: string | number, value: DocumentTypeEnum | string) {
    return apiInstance.patch(`/document/${docId}/reclassify`, { to: value });
  }

  getDocumentTypes(deliveryId: string | number) {
    return apiInstance.get(`/document/document-types?salesDeliveryId=${deliveryId}`).then((res) => {
      return (
        res.data as {
          presentableName: string;
          constantName: DocumentTypeEnum;
        }[]
      )
        .map((dt) => ({ label: dt.presentableName, value: dt.constantName }))
        .sort((a, b) => a.label.localeCompare(b.label));
    });
  }

  getDocumentTypesForSharedDelivery(deliveryId: string | number) {
    return apiInstance
      .get(`/document/document-types-for-share?salesDeliveryId=${deliveryId}`)
      .then((res) =>
        (
          res.data as {
            presentableName: string;
            constantName: DocumentTypeEnum;
          }[]
        )
          .map((dt) => ({ label: dt.presentableName, value: dt.constantName }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
  }

  updateDeliveryInfoFromDocument(deliveryId: number, documentId: number) {
    return apiInstance
      .patch(`/delivery/${deliveryId}/by-document`, {
        salesDeliveryDocumentId: documentId,
      })
      .then((res) => res.data as { updated: IDeliveryInfoFromDocument; warning?: string | null });
  }

  async updateSalesorderDetails(orderId: number, body: IISalesOrderDetailsUpdate) {
    return await apiInstance.patch(`/order/${orderId}`, body);
  }

  updateSalesOrder(
    salesOrderId: number,
    data: { deliveryQuantity?: DeliveryQuantity | null; incotermLocation?: string }
  ) {
    return apiInstance.patch(`/order/${salesOrderId}/delivery-quantity`, data);
  }

  getEquipmentLoadPlan(outboundLogisticsId: string | number) {
    return apiInstance.get(`/logistics/${outboundLogisticsId}/equipment-load-plan`).then((res) => {
      let data = res.data as DeliveryEquipmentLoadPlan;
      if (!data.equipmentLoadPlan?.solutions) {
        data.equipmentLoadPlan = null;
      }
      data.packagingItems = data.packagingItems.map((i) => ({
        ...i,
        name: i.packageName || "",
        weight: i.netWeight || 0,
      }));
      return data;
    });
  }
  getPalletLoadPlan(outboundLogisticsId: string | number) {
    return apiInstance.get(`/logistics/${outboundLogisticsId}/pallet-load-plan`).then((res) => {
      const data = res.data as DeliveryEquipmentLoadPlan;
      if (!data.palletLoadPlan?.solutions) {
        data.palletLoadPlan = null;
      }
      data.packagingItems = data.packagingItems.map((i) => ({
        ...i,
        name: i.packageName || "",
        weight: i.netWeight || 0,
        uomDimension: data.warehouseData.uomDimension,
        uomWeight: data.warehouseData.uomWeight,
      }));
      return data;
    });
  }

  markSectionComplete({ section, id }: { section: DeliverySectionType; id: string | number }) {
    return apiInstance.post(`/completion/${id}/section`, { section });
  }

  markTrackingSectionComplete({ id }: { id: string | number }) {
    return apiInstance.patch(`/transit-tracking/${id}/data-completion`);
  }

  markDelivered({ id }: { id: string | number }) {
    return apiInstance.patch(`/delivery/${id}/complete`);
  }

  async getNylasDeliveryMails(
    deliveryId: number,
    isConsolidated?: boolean,
    nextCursor?: string
  ): Promise<GetNylasMailsResponse> {
    const response = await apiInstance.get(
      isConsolidated
        ? `/consolidated-delivery/${deliveryId}/emails?skip=0&take=500`
        : `/delivery/${deliveryId}/emails`,
      { params: { nextCursor } }
    );
    return response.data;
  }

  async deleteNylasDeliveryMail(threadIds: (string | number)[]) {
    return Promise.all(threadIds.map((id) => apiInstance.delete(`/nylas/email/${id}`)));
  }

  getDeliveryCompletion(deliveryId: string | number) {
    return apiInstance
      .get(`/delivery/${deliveryId}/completion`)
      .then((res) => res.data.completion as GetDeliveryCompletionResponse);
  }
  async dispatchDelivery(id: number) {
    const response = await apiInstance.patch(`/delivery/${id}/dispatch`);
    return response.data;
  }

  async getShareDeliveryDocumentsFile(s3Uri: string, token: string) {
    return apiInstance.get(`/delivery/documents/file?s3Uri=${s3Uri}`, {
      headers: {
        Authorization: token,
      },
    });
  }
  async replaceDocument({ id, file }: { id: string | number; file: Blob }) {
    const response = await apiInstance.patch(
      `/document/${id}/replace`,
      {
        file,
      },
      { headers: authHeader("multipart/form-data") }
    );
    return response.data;
  }

  async bookTransportation({
    id,
    logisticsServiceProviderId,
    bookingService,
    lspOptionsTransportCost,
  }: {
    id: string | number;
    logisticsServiceProviderId: number;
    bookingService: BOOKING_TYPE;
    lspOptionsTransportCost?: ILSPTransportCost[];
  }) {
    return apiInstance.post(`/delivery/${id}/book-transportation`, {
      logisticsServiceProviderId,
      bookingService,
      lspOptionsTransportCost,
    });
  }

  async bookDSVExpress({
    id,
    logisticsServiceProviderId,
    lspOptionsTransportCost,
  }: {
    id: string | number;
    logisticsServiceProviderId: number;
    lspOptionsTransportCost?: ILSPTransportCost[];
  }) {
    return apiInstance.post(`/booking-api/dsv-express/${id}`, {
      logisticsServiceProviderId,
      lspOptionsTransportCost,
    });
  }

  async bookDHLExpress({
    id,
    logisticsServiceProviderId,
    lspOptionsTransportCost,
  }: {
    id: string | number;
    logisticsServiceProviderId: number;
    lspOptionsTransportCost?: ILSPTransportCost[];
  }) {
    return apiInstance.post(`/booking-api/dhl-express/${id}`, {
      logisticsServiceProviderId,
      lspOptionsTransportCost,
    });
  }

  async bookDHLEcommerce({
    id,
    logisticsServiceProviderId,
    lspOptionsTransportCost,
  }: {
    id: string | number;
    logisticsServiceProviderId: number;
    lspOptionsTransportCost?: ILSPTransportCost[];
  }) {
    return apiInstance.post(`/booking-api/dhl-ecommerce/${id}`, {
      logisticsServiceProviderId,
      lspOptionsTransportCost,
    });
  }

  async bookGLSEcommerce({
    id,
    logisticsServiceProviderId,
    lspOptionsTransportCost,
  }: {
    id: string | number;
    logisticsServiceProviderId: number;
    lspOptionsTransportCost?: ILSPTransportCost[];
  }) {
    return apiInstance.post(`/booking-api/gls-ecommerce/${id}`, {
      logisticsServiceProviderId,
      lspOptionsTransportCost,
    });
  }

  async bookDbSchenker({
    id,
    logisticsServiceProviderId,
  }: {
    id: string | number;
    logisticsServiceProviderId: number;
  }) {
    return apiInstance.post(`/booking-api/dbschenker/${id}`, {
      logisticsServiceProviderId,
    });
  }

  async bookTransportationLogisticsProviderDraft({
    id,
    customerLogisticsProviderId,
  }: {
    id: string | number;
    customerLogisticsProviderId: number;
  }) {
    return apiInstance.post(`booking-transportation/${id}/lsp`, {
      customerLogisticsProviderId,
    });
  }

  async getBookTransportationPrerequisites(deliveryId: string | number) {
    return apiInstance
      .get(`/booking-transportation/pre-requisites/${deliveryId}`)
      .then((res) => res.data as BookTransportPreRequisites);
  }

  async getShippingLabel({ s3Uri, format }: { s3Uri: string; format: string }) {
    return apiInstance
      .get("/delivery/shipping-label/download", { params: { s3Uri: s3Uri } })
      .then((response) => {
        return new Promise((res) => {
          const array = new Uint8Array(response.data.data);
          const fileBlob = new Blob([array], { type: format });
          const reader = new FileReader();
          reader.onload = () => {
            res({
              dataUrl: reader.result as string,
              fileBlob,
            });
          };
          reader.readAsDataURL(fileBlob);
        });
      })
      .catch(() => {}) as Promise<void | { dataUrl: string; fileBlob: Blob }>;
  }

  async getShippingLabelZPLContent({
    s3Uri,
    format = "text/plain",
  }: {
    s3Uri: string;
    format: string;
  }) {
    return apiInstance
      .get("/delivery/shipping-label/download", { params: { s3Uri: s3Uri } })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const array = new Uint8Array(response.data.data);
          const fileBlob = new Blob([array], { type: format });

          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              zplContent: reader.result as string,
              fileBlob,
            });
          };
          reader.onerror = () => reject(reader.error);
          reader.readAsText(fileBlob);
        });
      })
      .catch((error) => {
        console.error("Error fetching shipping label:", error);
        throw error;
      }) as Promise<void | { zplContent: string; fileBlob: Blob }>;
  }

  async getShippingLabelBlob({ s3Uri }: { s3Uri: string }) {
    return apiInstance
      .get("/delivery/shipping-label/download", { params: { s3Uri: s3Uri } })
      .then((response) => {
        const mimetype = getMimeTypeFromArrayBuffer(response.data.data);
        const array = new Uint8Array(response.data.data);
        const fileBlob = new Blob([array], { type: mimetype || "image/jpeg" });
        return URL.createObjectURL(fileBlob);
      })
      .catch(() => "") as Promise<string>;
  }

  async sendDocsToLogisticsProvider({
    deliveryId,
    docIds,
    descriptions,
  }: {
    deliveryId: number;
    docIds: number[];
    descriptions?: string[];
  }) {
    return apiInstance.post(`/booking-api/dsv-express/${deliveryId}/sendDocuments`, {
      salesDeliveryDocumentIds: docIds,
      descriptions: descriptions,
    });
  }

  async getConsolidateDeliveries(
    params: IGetConsolidateDeliveriesRequest
  ): Promise<IGetConsolidateDeliveriesResponseItem[] | undefined> {
    if (!params.destinationCountryId || !params.modeOfTransports) return undefined;
    return apiInstance.get("/consolidated-delivery/list", { params }).then((res) => res.data);
  }

  postConsolidateDeliveries(params: IPostConsolidateDeliveryRequest) {
    return apiInstance.post(`/consolidated-delivery/consolidate`, {
      ...params,
    });
  }

  unconsolidateDelivery(consolidatedDeliveryId: number, salesDeliveryIds: number[]) {
    return apiInstance.post(
      `/consolidated-delivery/unconsolidate/${consolidatedDeliveryId}/detailed`,
      { salesDeliveryIds }
    );
  }

  async getDeliveryCustomDocNames(
    salesDeliveryId: number,
    params: { transportationModeId?: number | string; attachable?: boolean; generatable?: boolean }
  ) {
    return apiInstance
      .get(`/delivery/${salesDeliveryId}/custom-document-type`, {
        params,
      })
      .then((res) => res.data as { id: number; name: string; transportationModeId: number }[]);
  }

  async callDeposco(data: { salesDeliveryId: number; eta?: string }) {
    return apiInstance.post("broadcast/deposco", data).then((res) => res.data);
  }

  async getBroadcastConfiguration(salesDeliveryId: string | number) {
    return apiInstance.get(`broadcast/configuration/${salesDeliveryId}`).then(
      (res) =>
        res.data as {
          configuration: { integration: string; module: "sales" }[];
          state: { [key: string]: "ok" | "error" | "ongoing" | null };
        }
    );
  }

  async getTradePolicies(deliveryId: number) {
    return apiInstance.get(`delivery/${deliveryId}/trade-policies`).then(
      (res) =>
        res.data as {
          tradePolicies: DeliveryTradePolicy[];
          count: number;
        }
    );
  }
  async getLSPOptionsData(ids: number[]) {
    return apiInstance.get("booking-transportation/lsp-service-level-information", {
      params: { lspIds: ids.join(",") },
    });
  }

  async getLatestPickupDateForCustomer() {
    return apiInstance.get(`booking-transportation/latest-pickup-date`).then((res) => {
      return res.data;
    });
  }

  async generateDeliveryDocument(payload: { deliveryId: number; documentLabel: string }) {
    return apiInstance
      .get(
        `delivery/${payload.deliveryId}/generate-delivery-document?documentLabel=${payload.documentLabel}`
      )
      .then((res) => {
        return res.data;
      });
  }

  async getPONumbers(page: "in-queue" | "dispatched") {
    return apiInstance
      .get(`/delivery/po-numbers/${page}`)
      .then((res) => res.data.filter(Boolean) as string[]);
  }
}

export default new DeliveryService();
