import { BOOKING_TYPE } from "holocene-components/delivery/BookingForms/constants";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import deliveryService from "holocene-services/delivery.service";
import { ILSPTransportCost, OutboundLogistics } from "holocene-services/delivery.service/types";
import logisticsService from "holocene-services/logistics.service";
import {
  CreateLogisticsProviderRequest,
  PatchLSPPayload,
} from "holocene-services/logistics.service/type";
import { useMutation, useQuery } from "react-query";

export const useUpdateLogisticsDetails = (id: number, isConsolidated?: boolean) =>
  useMutation((request: Partial<OutboundLogistics>) =>
    isConsolidated
      ? logisticsService.updateConsolidatedLogistics(id, request)
      : logisticsService.updateLogistics(id, request)
  );

export const useUpdateLogisticsAndBookTransport = (
  id: number,
  logisticsServiceProviderId: number,
  bookingService: BOOKING_TYPE
) =>
  useMutation((request: Partial<OutboundLogistics>) =>
    logisticsService.updateLogisticsAndBookTransportation(
      id,
      request,
      logisticsServiceProviderId,
      bookingService
    )
  );

export const useBookDSVExpress = (
  id: number,
  logisticsServiceProviderId: number,
  lspOptionsTransportCost?: ILSPTransportCost[]
) =>
  useMutation(() =>
    deliveryService.bookDSVExpress({ id, logisticsServiceProviderId, lspOptionsTransportCost })
  );

export const useBookDHLExpress = (
  id: number,
  logisticsServiceProviderId: number,
  lspOptionsTransportCost?: ILSPTransportCost[]
) =>
  useMutation(() =>
    deliveryService.bookDHLExpress({ id, logisticsServiceProviderId, lspOptionsTransportCost })
  );

export const useBookDHLEcommerce = (
  id: number,
  logisticsServiceProviderId: number,
  lspOptionsTransportCost?: ILSPTransportCost[]
) =>
  useMutation(() =>
    deliveryService.bookDHLEcommerce({ id, logisticsServiceProviderId, lspOptionsTransportCost })
  );

export const useBookGLSEcommerce = (
  id: number,
  logisticsServiceProviderId: number,
  lspOptionsTransportCost?: ILSPTransportCost[]
) =>
  useMutation(() =>
    deliveryService.bookGLSEcommerce({ id, logisticsServiceProviderId, lspOptionsTransportCost })
  );

export const useBookDbSchenker = (id: number, logisticsServiceProviderId: number) =>
  useMutation(() => deliveryService.bookDbSchenker({ id, logisticsServiceProviderId }));

export const useGetModesOfTransportation = () => {
  return useQuery(
    [QUERY_CONSTANTS.DELIVERY_MODES_OF_TRANSPORTATION],
    logisticsService.getModesOfTransportations
  );
};

export const useGetLogisticsProviders = () => {
  return useQuery([QUERY_CONSTANTS.LOGISTICS_PROVIDERS], () =>
    logisticsService.getLogisticProviders()
  );
};

export const usePostCreateLogisticsProvider = () => {
  return useMutation((request: CreateLogisticsProviderRequest) =>
    logisticsService.postCreateLogisticsProvider(request)
  );
};

export const useGetLogisticsProvider = (id: number) => {
  return useQuery([QUERY_CONSTANTS.LOGISTICS_PROVIDER, id], () =>
    logisticsService.getLogisticsProvider(id)
  );
};

export const usePatchLogisticsProviders = () => {
  return useMutation((data: PatchLSPPayload) => logisticsService.patchLogisticsProvider(data));
};

export const useDeleteLogisticsProvider = () => {
  return useMutation((id: number) => logisticsService.deleteLogisticsProvider(id));
};

export const useGetCustomerNames = () => {
  return useQuery(QUERY_CONSTANTS.CUSTOMER_NAMES, logisticsService.getCustomerNames);
};
