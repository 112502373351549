import { localeNumber } from "holocene-utils/helpers";
import _ from "lodash";

export type InputType =
  | "text"
  | "number"
  | "country"
  | "table"
  | "date"
  | "currency"
  | "multi-string";

export interface FieldConfig {
  type: InputType;
  keyName: string;
  label?: string;
  tableColumns?: FieldConfig[];
  disabled?: boolean;
  variant?: "underlined" | "borderless";
}

const samples = {
  createdAt: null,
  deliveryNumber: null,
  requestedShippingDate: null,
  SalesDeliveryProducts: {
    type: "table",
    keyName: "SalesDeliveryProducts",
    tableColumns: [
      {
        keyName: "productCode",
        label: "Code",
        type: "text",
      },
      { keyName: "productName", label: "Name", type: "text" },
      { keyName: "quantity", label: "Quantity", type: "number" },
    ],
  },
};

const sampleConfigProforma = {
  customerName: null,
  customerAddressVerified: null,
  customerAddressPostalCode: null,
  customerAddressCity: null,
  customerAddressState: null,
  customerAddressCountryName: null,

  "OutboundLogistics.deliveryName": null,
  "OutboundLogistics.deliveryAddress": null,
  "OutboundLogistics.deliveryAddressCity": null,
  "OutboundLogistics.deliveryAddressState": null,
  "OutboundLogistics.deliveryAddressCountryName": null,
  "OutboundLogistics.deliveryAddressPostalCode": null,

  createdAt: null,
  deliveryNumber: null,
  "SalesWarehouse.netWeight": null,
  "SalesWarehouse.grossWeight": null,
  "incoterm.code": null,
  incotermLocation: null,
  "OutboundLogistics.transportationMode.type": null,
  requestedShippingDate: null,
  "OutboundLogistics.bookingName": null,

  SalesDeliveryProducts: {
    type: "table",
    keyName: "SalesDeliveryProducts",
    tableColumns: [
      { keyName: "productName", label: "Name", type: "text" },
      {
        keyName: "Country.officialName",
        label: "Country",
        type: "country",
      },
      { keyName: "quantity", label: "Quantity", type: "number" },
      { keyName: "totalPrice", label: "Total Price", type: "number" },
      {
        keyName: "currency",
        label: "Currency",
        type: "currency",
      },
      { keyName: "hsCode", label: "HS Code", type: "multi-string" },
    ],
  },
  currency: null,
};

const sampleConfigCOO = {
  customerName: null,
  customerAddressVerified: null,
  customerAddressPostalCode: null,
  customerAddressCity: null,
  customerAddressState: null,
  customerAddressCountryName: null,
  deliveryNumber: null,
  "OutboundLogistics.transportationMode.type": null,
  "palletLoadPlan.totals.pallets": null,
  "SalesWarehouse.grossWeight": null,
  "SalesWarehouse.unitOfMeasurementWeight": null,

  customProductDescription: {
    type: "text",
    keyName: "customProductDescription",
    label: "Product Description",
  },
};

const sampleConfigPackingList = {
  customerName: null,
  customerAddressVerified: null,
  customerAddressPostalCode: null,
  customerAddressCity: null,
  customerAddressState: null,
  customerAddressCountryName: null,

  deliveryNumber: null,
  "OutboundLogistics.transportationMode.type": null,
  "SalesWarehouse.grossWeight": null,
  "SalesWarehouse.netWeight": null,
  "incoterm.code": null,
  incotermLocation: null,

  "SalesWarehouse.SalesWarehousePackaging": {
    type: "table",
    keyName: "SalesWarehouse.SalesWarehousePackaging",
    label: "Warehouse Packaging",
    tableColumns: [
      { keyName: "packageName", label: "Package Name", type: "text" },
      { keyName: "length", label: "Length", type: "number" },
      { keyName: "width", label: "Width", type: "number" },
      { keyName: "height", label: "Height", type: "number" },
      { keyName: "unitOfMeasurementDimension", label: "UoM Dimension", type: "text" },
      { keyName: "grossWeight", label: "Gross Weight", type: "number" },
      { keyName: "unitOfMeasurementWeight", label: "UoM Weight", type: "text" },
      { keyName: "productName", label: "Name", type: "text" },
      { keyName: "productCode", label: "Code", type: "text" },
      { keyName: "sku.dangerous", label: "Dangerous", type: "text" },
      { keyName: "sku.unNumber", label: "UN Number", type: "number" },
      { keyName: "sku.unClass", label: "UN Class", type: "text" },
      { keyName: "sku.palletized", label: "Palletized", type: "text" },
      { keyName: "sku.critical", label: "Critical", type: "text" },
      { keyName: "sku.packagingGroup", label: "Packaging Group", type: "text" },
    ],
  },
};

const sampleConfigCommercialInvoice = {
  customerName: null,
  customerAddressVerified: null,
  customerAddressPostalCode: null,
  customerAddressCity: null,
  customerAddressState: null,
  customerAddressCountryName: null,

  "OutboundLogistics.deliveryName": null,
  "OutboundLogistics.deliveryAddress": null,
  "OutboundLogistics.deliveryAddressCity": null,
  "OutboundLogistics.deliveryAddressState": null,
  "OutboundLogistics.deliveryAddressCountryName": null,
  "OutboundLogistics.deliveryAddressPostalCode": null,

  createdAt: null,
  deliveryNumber: null,
  "SalesWarehouse.netWeight": null,
  "SalesWarehouse.grossWeight": null,
  "incoterm.code": null,
  incotermLocation: null,
  "OutboundLogistics.transportationMode.type": null,
  requestedShippingDate: null,

  SalesDeliveryProducts: {
    type: "table",
    keyName: "SalesDeliveryProducts",
    tableColumns: [
      { keyName: "productName", label: "Name", type: "text" },
      {
        keyName: "Country.officialName",
        label: "Country",
        type: "country",
      },
      { keyName: "quantity", label: "Quantity", type: "number" },
      { keyName: "totalPrice", label: "Total Price", type: "number" },
      {
        keyName: "currency",
        label: "Currency",
        type: "currency",
      },
      { keyName: "hsCode", label: "HS Code", type: "multi-string" },
    ],
  },
  currency: null,

  customNotify: { keyName: "customNotify", label: "Notify", type: "text" },
};

const sampleConfigCommercialInvoiceIndia = {
  customerName: null,
  customerAddressVerified: null,
  customerAddressPostalCode: null,
  customerAddressCity: null,
  customerAddressState: null,
  customerAddressCountryName: null,

  "OutboundLogistics.deliveryName": null,
  "OutboundLogistics.deliveryAddress": null,
  "OutboundLogistics.deliveryAddressCity": null,
  "OutboundLogistics.deliveryAddressState": null,
  "OutboundLogistics.deliveryAddressCountryName": null,
  "OutboundLogistics.deliveryAddressPostalCode": null,

  createdAt: null,
  deliveryNumber: null,
  "SalesWarehouse.netWeight": null,
  "SalesWarehouse.grossWeight": null,
  "incoterm.code": null,
  incotermLocation: null,
  "OutboundLogistics.transportationMode.type": null,
  requestedShippingDate: null,

  SalesDeliveryProducts: {
    type: "table",
    keyName: "SalesDeliveryProducts",
    tableColumns: [
      { keyName: "productName", label: "Name", type: "text" },
      {
        keyName: "Country.officialName",
        label: "Country",
        type: "country",
      },
      { keyName: "quantity", label: "Quantity", type: "number" },
      { keyName: "totalPrice", label: "Total Price", type: "number" },
      {
        keyName: "currency",
        label: "Currency",
        type: "currency",
      },
      { keyName: "hsCode", label: "HS Code", type: "multi-string" },
    ],
  },
  currency: null,

  customIECNumber: { keyName: "customIECNumber", label: "IEC Number", type: "text" },
};

export const DEFAULT_DELIVERY_FIELD_CONFIG_MAPPING: { [key: string]: FieldConfig } = {
  "salesOrder.soNumber": { type: "text", keyName: "salesOrder.soNumber", label: "SO Number" },
  poNumber: { type: "text", keyName: "poNumber", label: "PO Number" },
  deliveryNumber: { type: "text", keyName: "deliveryNumber", label: "Delivery Number" },
  dispatchedAt: { type: "date", keyName: "dispatchedAt", label: "Dispatched date" },
  deliveredAt: { type: "date", keyName: "deliveredAt", label: "Delivered date" },
  incotermLocation: { type: "text", keyName: "incotermLocation", label: "Incoterm Location" },
  "incoterm.code": { type: "text", keyName: "incoterm.code", label: "Incoterm Code" },
  customerName: { type: "text", keyName: "customerName", label: "Name" },
  customerAddressVerified: { type: "text", keyName: "customerAddressVerified", label: "Addresss" },
  customerAddressPostalCode: {
    type: "text",
    keyName: "customerAddressPostalCode",
    label: "Postal Code",
  },
  customerAddressCity: { type: "text", keyName: "customerAddressCity", label: "City" },
  customerAddressState: { type: "text", keyName: "customerAddressState", label: "State" },
  customerAddressCountryName: {
    type: "country",
    keyName: "customerAddressCountryName",
    label: "Country",
  },
  contact: { type: "text", keyName: "contact", label: "Contact" },
  requestedShippingDate: {
    type: "date",
    keyName: "requestedShippingDate",
    label: "Requested shipping date",
  },
  confirmedShippingDate: {
    type: "date",
    keyName: "confirmedShippingDate",
    label: "Confirmed shipping date",
  },
  currency: { type: "text", keyName: "currency", label: "Currency" },
  totalAmount: { type: "number", keyName: "totalAmount", label: "Total Amount" },
  SalesDeliveryProducts: {
    type: "table",
    keyName: "SalesDeliveryProducts",
    tableColumns: [
      { keyName: "productName", label: "Name", type: "text" },
      { keyName: "productCode", label: "Code", type: "text" },
      { keyName: "shippingName", label: "Shipping Name", type: "text" },
      { keyName: "description", label: "Description", type: "text" },
      {
        keyName: "Country.officialName",
        label: "Country",
        type: "country",
      },
      { keyName: "quantity", label: "Quantity", type: "number" },
      { keyName: "unitPrice", label: "Unit Price", type: "number" },
      { keyName: "totalPrice", label: "Total Price", type: "number" },
      {
        keyName: "currency",
        label: "Currency",
        type: "currency",
      },
      { keyName: "hsCode", label: "HS Code", type: "multi-string" },
    ],
  },
  dangerousGoods: {
    type: "table",
    keyName: "dangerousGoods",
    tableColumns: [
      { keyName: "productName", label: "Name", type: "text" },
      { keyName: "shippingName", label: "Shipping Name", type: "text" },
      { keyName: "productCode", label: "Code", type: "text" },
      { keyName: "description", label: "Description", type: "text" },
      {
        keyName: "Country.alpha2Code",
        label: "Country",
        type: "country",
      },
      { keyName: "quantity", label: "Quantity", type: "number" },
      { keyName: "unitPrice", label: "Unit Price", type: "number" },
      { keyName: "totalPrice", label: "Total Price", type: "number" },
      {
        keyName: "currency",
        label: "Currency",
        type: "currency",
      },
      { keyName: "hsCode", label: "HS Code", type: "multi-string" },
      { keyName: "stockKeepingUnit.itemName", label: "Name", type: "text" },
      { keyName: "stockKeepingUnit.itemDescription", label: "Description", type: "text" },
      { keyName: "stockKeepingUnit.shippingName", label: "Shipping Name", type: "text" },
      { keyName: "stockKeepingUnit.itemCode", label: "Item Code", type: "text" },
      { keyName: "stockKeepingUnit.cargoType", label: "Cargo Type", type: "text" },
      { keyName: "stockKeepingUnit.uom", label: "Unit", type: "text" },
      { keyName: "stockKeepingUnit.hsCode", label: "HS Code", type: "text" },
      { keyName: "stockKeepingUnit.unNumber", label: "UN Number", type: "text" },
      { keyName: "stockKeepingUnit.unClass", label: "UN Class", type: "text" },
      { keyName: "stockKeepingUnit.emsCode", label: "EMS Code", type: "text" },
      { keyName: "stockKeepingUnit.packagingGroup", label: "Packaging group", type: "text" },
      { keyName: "stockKeepingUnit.flashPointValue", label: "Flash point value", type: "number" },
      { keyName: "stockKeepingUnit.flashPointUom", label: "Flash point unit", type: "text" },
      { keyName: "stockKeepingUnit.netWeight", label: "Net weight", type: "number" },
      { keyName: "stockKeepingUnit.grossWeight", label: "Gross weight", type: "number" },
      { keyName: "stockKeepingUnit.itemLength", label: "Item Length", type: "number" },
      { keyName: "stockKeepingUnit.itemWidth", label: "Item Width", type: "number" },
      { keyName: "stockKeepingUnit.itemHeight", label: "Item Height", type: "number" },
    ],
  },
  "salesOrder.originCountry.officialName": {
    type: "country",
    label: "Origin Country",
    keyName: "salesOrder.originCountry.officialName",
  },
  "SalesWarehouse.pickDate": {
    type: "date",
    keyName: "SalesWarehouse.pickDate",
    label: "Pick Date",
  },
  "SalesWarehouse.packDate": {
    type: "date",
    keyName: "SalesWarehouse.packDate",
    label: "Pack Date",
  },
  "SalesWarehouse.netWeight": {
    type: "number",
    keyName: "SalesWarehouse.netWeight",
    label: "Net Weight",
  },
  "SalesWarehouse.grossWeight": {
    type: "number",
    keyName: "SalesWarehouse.grossWeight",
    label: "Gross Weight",
  },
  "SalesWarehouse.unitOfMeasurementWeight": {
    type: "text",
    keyName: "SalesWarehouse.unitOfMeasurementWeight",
    label: "UoM Weight",
  },
  "SalesWarehouse.unitOfMeasurementDimension": {
    type: "text",
    keyName: "SalesWarehouse.unitOfMeasurementDimension",
    label: "UoM Dimension",
  },
  "SalesWarehouse.SalesWarehousePackaging": {
    type: "table",
    keyName: "SalesWarehouse.SalesWarehousePackaging",
    label: "Warehouse Packaging",
    tableColumns: [
      { keyName: "packageName", label: "Package Name", type: "text" },
      { keyName: "length", label: "Length", type: "number" },
      { keyName: "width", label: "Width", type: "number" },
      { keyName: "height", label: "Height", type: "number" },
      { keyName: "unitOfMeasurementDimension", label: "UoM Dimension", type: "text" },
      { keyName: "netWeight", label: "Net Weight", type: "number" },
      { keyName: "grossWeight", label: "Gross Weight", type: "number" },
      { keyName: "unitOfMeasurementWeight", label: "UoMWeight", type: "text" },
      { keyName: "productName", label: "Name", type: "text" },
      { keyName: "productCode", label: "Code", type: "text" },
      { keyName: "sku.dangerous", label: "Dangerous", type: "text" },
      { keyName: "sku.unNumber", label: "UN Number", type: "number" },
      { keyName: "sku.unClass", label: "UN Class", type: "text" },
      { keyName: "sku.palletized", label: "Palletized", type: "text" },
      { keyName: "sku.critical", label: "Critical", type: "text" },
      { keyName: "sku.packagingGroup", label: "Packaging Group", type: "text" },
    ],
  },
  "OutboundLogistics.deliveryName": {
    keyName: "OutboundLogistics.deliveryName",
    label: "Name",
    type: "text",
  },
  "OutboundLogistics.deliveryAddressVerified": {
    keyName: "OutboundLogistics.deliveryAddressVerified",
    label: "Address",
    type: "text",
  },
  "OutboundLogistics.contact": {
    keyName: "OutboundLogistics.contact",
    label: "Contact",
    type: "text",
  },
  "OutboundLogistics.contactNumber": {
    keyName: "OutboundLogistics.contactNumber",
    label: "Contact Number",
    type: "text",
  },
  "OutboundLogistics.vesselNames": {
    keyName: "OutboundLogistics.vesselNames",
    label: "Vessel Names",
    type: "multi-string",
  },
  "OutboundLogistics.deliveryAddressCity": {
    keyName: "OutboundLogistics.deliveryAddressCity",
    label: "City",
    type: "text",
  },
  "OutboundLogistics.deliveryAddressState": {
    keyName: "OutboundLogistics.deliveryAddressState",
    label: "State",
    type: "text",
  },
  "OutboundLogistics.deliveryAddressCountryName": {
    keyName: "OutboundLogistics.deliveryAddressCountryName",
    label: "Country",
    type: "country",
  },
  "OutboundLogistics.deliveryAddressPostalCode": {
    keyName: "OutboundLogistics.deliveryAddressPostalCode",
    label: "Postal Code",
    type: "text",
  },
  "OutboundLogistics.pickupDate": {
    keyName: "OutboundLogistics.pickupDate",
    label: "Pickup Date",
    type: "date",
  },
  "OutboundLogistics.firstVesselDepartureDate": {
    keyName: "OutboundLogistics.firstVesselDepartureDate",
    label: "First Vessel Departure Date",
    type: "date",
  },
  "OutboundLogistics.documentCutoffDate": {
    keyName: "OutboundLogistics.documentCutoffDate",
    label: "Document Cut-off Date",
    type: "date",
  },
  "OutboundLogistics.bookingConfirmationNumber": {
    keyName: "OutboundLogistics.bookingConfirmationNumber",
    label: "Booking Confirmation Number",
    type: "text",
  },
  "OutboundLogistics.transportationMode.type": {
    keyName: "OutboundLogistics.transportationMode.type",
    label: "Transport mode",
    type: "text",
  },
  "OutboundLogistics.sealineStandardCarrierAlphaCode": {
    keyName: "OutboundLogistics.sealineStandardCarrierAlphaCode",
    label: "Sealine Standard Carrier Alpha Code",
    type: "text",
  },
  "OutboundLogistics.sealineStandardCarrierName": {
    keyName: "OutboundLogistics.sealineStandardCarrierName",
    label: "Sealine Standard Carrier Name",
    type: "text",
  },
  "OutboundLogistics.bookingName": {
    keyName: "OutboundLogistics.bookingName",
    label: "Booking Name",
    type: "text",
  },
  "OutboundLogistics.OutboundTracking": {
    keyName: "OutboundLogistics.OutboundTracking",
    label: "Tracking Details",
    type: "table",
    tableColumns: [
      { keyName: "trackingNumber", label: "Tracking Number", type: "text" },
      { keyName: "trackingProvider", label: "Tracking Provider", type: "text" },
    ],
  },
  "OutboundLogistics.trackingNumbers": {
    keyName: "OutboundLogistics.trackingNumbers",
    label: "Tracking Number",
    type: "text",
  },
  "equipmentLoadPlan.totals.weight": {
    keyName: "equipmentLoadPlan.totals.weight",
    type: "number",
    label: "Weight",
  },
  "equipmentLoadPlan.totals.tareWeight": {
    keyName: "equipmentLoadPlan.totals.tareWeight",
    type: "number",
    label: "Tare Weight",
  },
  "equipmentLoadPlan.totals.volume": {
    keyName: "equipmentLoadPlan.totals.volume",
    type: "number",
    label: "Volume",
  },
  "equipmentLoadPlan.totals.pallets": {
    keyName: "equipmentLoadPlan.totals.pallets",
    type: "number",
    label: "Pallets",
  },
  "equipmentLoadPlan.containers": {
    keyName: "equipmentLoadPlan.containers",
    type: "table",
    label: "Containers",
    tableColumns: [
      { keyName: "name", label: "Type", type: "text" },
      { keyName: "WT", label: "Weight", type: "number" },
      { keyName: "tare", label: "Tare Weight", type: "number" },
      { keyName: "L", label: "Length", type: "number" },
      { keyName: "W", label: "Width", type: "number" },
      { keyName: "H", label: "Height", type: "number" },
    ],
  },
  "palletLoadPlan.totals.weight": {
    keyName: "palletLoadPlan.totals.weight",
    type: "number",
    label: "Weight",
  },
  "palletLoadPlan.totals.tareWeight": {
    keyName: "palletLoadPlan.totals.tareWeight",
    type: "number",
    label: "Tare Weight",
  },
  "palletLoadPlan.totals.volume": {
    keyName: "palletLoadPlan.totals.volume",
    type: "number",
    label: "Volume",
  },
  "palletLoadPlan.totals.pallets": {
    keyName: "palletLoadPlan.totals.pallets",
    type: "number",
    label: "Pallets",
  },
  "palletLoadPlan.containers": {
    keyName: "palletLoadPlan.containers",
    type: "table",
    label: "Containers",
    tableColumns: [
      { keyName: "name", label: "Type", type: "text" },
      { keyName: "WT", label: "Weight", type: "number" },
      { keyName: "tare", label: "Tare Weight", type: "number" },
      { keyName: "L", label: "Length", type: "number" },
      { keyName: "W", label: "Width", type: "number" },
      { keyName: "H", label: "Height", type: "number" },
    ],
  },
  createdAt: { keyName: "createdAt", label: "Date", type: "date" },
  "selectedTrip.originPortCode": {
    type: "text",
    label: "Origin Port",
    keyName: "selectedTrip.originPortCode",
  },
  "selectedTrip.destinationPortCode": {
    type: "text",
    label: "Destination Port",
    keyName: "selectedTrip.destinationPortCode",
  },
};

export const TEMPLATE_FORM_SECTIONS_MAPPING = [
  {
    title: "Order Details",
    keys: [
      "salesOrder.soNumber",
      "poNumber",
      "deliveryNumber",
      "dispatchedAt",
      "deliveredAt",
      "incoterm.code",
      "incotermLocation",
      "requestedShippingDate",
      "confirmedShippingDate",
      "currency",
      "totalAmount",
      "salesOrder.originCountry.officialName",
      "createdAt",
    ],
  },
  {
    title: "Customer Details",
    keys: [
      "customerName",
      "customerAddressVerified",
      "customerAddressCity",
      "customerAddressState",
      "customerAddressCountryName",
      "customerAddressPostalCode",
      "contact",
    ],
  },
  {
    title: "Delivery Details",
    keys: [
      "OutboundLogistics.deliveryName",
      "OutboundLogistics.deliveryAddressVerified",
      "OutboundLogistics.deliveryAddressCity",
      "OutboundLogistics.deliveryAddressState",
      "OutboundLogistics.deliveryAddressCountryName",
      "OutboundLogistics.deliveryAddressPostalCode",
      "OutboundLogistics.contact",
    ],
  },
  { title: "Products", keys: ["SalesDeliveryProducts"] },
  { title: "Dangerous Goods", keys: ["dangerousGoods"] },
  {
    title: "Sales Warehouse",
    keys: [
      "SalesWarehouse.pickDate",
      "SalesWarehouse.packDate",
      "SalesWarehouse.netWeight",
      "SalesWarehouse.grossWeight",
      "SalesWarehouse.unitOfMeasurementWeight",
      "SalesWarehouse.unitOfMeasurementDimension",
      "SalesWarehouse.SalesWarehousePackaging",
    ],
  },
  {
    title: "Outbound Logistics",
    keys: [
      "OutboundLogistics.trackingNumbers",
      "OutboundLogistics.vesselNames",
      "OutboundLogistics.contactNumber",
      "OutboundLogistics.pickupDate",
      "OutboundLogistics.firstVesselDepartureDate",
      "OutboundLogistics.documentCutoffDate",
      "OutboundLogistics.bookingConfirmationNumber",
      "OutboundLogistics.transportationMode.type",
      "OutboundLogistics.sealineStandardCarrierAlphaCode",
      "OutboundLogistics.sealineStandardCarrierName",
      "OutboundLogistics.bookingName",
      "OutboundLogistics.OutboundTracking",
    ],
  },
  {
    title: "Trip details",
    keys: ["selectedTrip.originPortCode", "selectedTrip.destinationPortCode"],
  },
  {
    title: "Equipment Load Plan",
    keys: [
      "equipmentLoadPlan.totals.weight",
      "equipmentLoadPlan.totals.tareWeight",
      "equipmentLoadPlan.totals.volume",
      "equipmentLoadPlan.totals.pallets",
      "equipmentLoadPlan.containers",
    ],
  },
  {
    title: "Pallet Load Plan",
    keys: [
      "palletLoadPlan.totals.weight",
      "palletLoadPlan.totals.tareWeight",
      "palletLoadPlan.totals.volume",
      "palletLoadPlan.totals.pallets",
      "palletLoadPlan.containers",
    ],
  },
];

export const formatNumberValuesForDocument = (
  values: any,
  templateConfig?: { [key: string]: FieldConfig | null }
) => {
  if (!templateConfig) return values;
  const updatedValues = _.cloneDeep(values);

  Object.entries(templateConfig).forEach(([key, config]) => {
    const fieldConfig = config ?? DEFAULT_DELIVERY_FIELD_CONFIG_MAPPING[key];
    if (!fieldConfig) return;
    if (fieldConfig.type === "number") {
      const value = _.get(updatedValues, key);
      if (value !== undefined) {
        _.set(
          updatedValues,
          key,
          localeNumber(value, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      }
    } else if (fieldConfig.type === "table") {
      const rows = _.get(updatedValues, fieldConfig.keyName);
      if (!rows || rows.length === 0) return;
      fieldConfig.tableColumns?.forEach((field) => {
        if (field.type === "number") {
          rows.forEach((row: any) => {
            const value = _.get(row, field.keyName);
            if (value !== undefined) {
              _.set(
                row,
                field.keyName,
                localeNumber(value, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              );
            }
          });
        }
      });
    }
  });
  return updatedValues;
};
