import { useCallback } from "react";
import {
  useAlertWebSocket,
  WebsocketAlert,
  WebsocketAlertAction,
  WebsocketAlertModelName,
} from "./alertWebSocket.hooks";
import { useGetUserInfo } from "./users.hooks";
import { INotificationTypes, useNotification } from "holocene-providers/common";

export const useBookingTransportationWebhookListener = () => {
  const { data: user } = useGetUserInfo();
  const { addNotification } = useNotification();

  const handleBookingTransportationAlert = useCallback(
    async (alert: WebsocketAlert) => {
      if (
        alert.modelName === WebsocketAlertModelName.BOOK_TRANSPORTATION &&
        alert.action === WebsocketAlertAction.UPDATE
      ) {
        addNotification({
          type: INotificationTypes.Success,
          message: "Holocene created a booking for you",
        });
      }
    },
    [user]
  );

  useAlertWebSocket(handleBookingTransportationAlert);

  return null;
};
