import React, { useEffect, useMemo, useState } from "react";
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import { CaretLeft, CaretRight } from "phosphor-react";
import CustomDropdown from "./CustomDropdown";

import "react-datepicker/dist/react-datepicker.css";
import { dateUtils } from "holocene-utils/date.utils";

const range = (start: number, end: number) => {
  return new Array(end - start + 1).fill(0).map((_, i) => i + start);
};

const years = range(1990, getYear(new Date()) + 1);
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export interface CustomDatePickerProps {
  handleDateChange: (date: Date | null) => void;
  value?: Date | null;
  disabled?: boolean;
  minDate?: Date;
  label?: string;
  labelSize?: string;
  showTimeInput?: boolean | undefined;
  timeInputLabel?: string | undefined;
  dateFormat?: string | undefined;
  isClearable?: boolean;
  placeholder?: string;
  showIcon?: boolean;
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  iconPosition?: "left" | "right" | undefined;
  className?: string;
  error?: string;
  hasError?: boolean;
  mainClassName?: string;
  excludeTimes?: Date[];
  filterDate?: (date: Date) => boolean;
  onCalendarClose?: () => void;
  showIconOnly?: boolean;
  maxDate?: Date;
}

const DatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  handleDateChange,
  value,
  disabled = false,
  minDate,
  showTimeInput,
  timeInputLabel,
  dateFormat,
  isClearable = false,
  placeholder,
  showIcon = false,
  icon,
  iconPosition = "left",
  className = "",
  labelSize = "text-xs",
  error,
  hasError,
  mainClassName = "",
  excludeTimes = [],
  filterDate,
  onCalendarClose,
  showIconOnly,
  maxDate,
}) => {
  const [startDate, setStartDate] = useState<Date | null>();

  const handleChange = (date: Date | null) => {
    setStartDate(date);
    if (date || isClearable) {
      handleDateChange(date);
    }
  };

  useEffect(() => {
    setStartDate(value);
  }, [value]);

  const validYearRange = useMemo(
    () => (minDate ? years.filter((year) => minDate.getFullYear() <= year) : years),
    [minDate]
  );

  const getValidMonthRange = (date: Date) => {
    if (minDate) {
      return minDate?.getFullYear() === date?.getFullYear()
        ? months.slice(minDate!.getMonth())
        : months;
    }
    return months;
  };

  const variantClassName = hasError
    ? "border border-danger focus:border-danger rounded-md"
    : "border-strokes rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-holocene-blue";

  return (
    <div>
      {label && (
        <div className={`${labelSize} text-secondary-text font-semibold mb-1`}>{label}</div>
      )}
      <ReactDatePicker
        disabled={disabled}
        className={`block w-full px-3 !py-2 placeholder-gray-400 transition duration-150 ease-in-out border ${variantClassName} focus:ring-0 sm:text-sm sm:leading-5 ${mainClassName} ${
          disabled ? "cursor-not-allowed bg-gray-100" : ""
        }`}
        wrapperClassName={`block w-full ${showIcon ? "custom-icon-pos-" + iconPosition : ""}`}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }: ReactDatePickerCustomHeaderProps) => (
          <div className="flex items-center">
            <button
              type="button"
              className="mr-7"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <CaretLeft size={20} className="text-holocene-blue" weight="bold" />
            </button>

            <CustomDropdown
              data={getValidMonthRange(date)}
              onChange={(value: string) => {
                changeMonth(months.indexOf(value));
              }}
              classes="mr-3.5"
              defaultValue={months[getMonth(date)]}
              value={months[getMonth(date)]}
            />

            <CustomDropdown
              defaultValue={getYear(date)}
              data={validYearRange}
              onChange={(value: string) => changeYear(parseInt(value))}
              value={getYear(date)}
            />

            <button
              type="button"
              className="ml-7"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <CaretRight size={20} className="text-holocene-blue" weight="bold" />
            </button>
          </div>
        )}
        selected={startDate}
        onChange={(date) => handleChange(date)}
        onCalendarClose={onCalendarClose}
        dateFormat={
          dateFormat
            ? dateFormat
            : showTimeInput
            ? dateUtils.getLocaleDateTimeFormat()
            : dateUtils.getDatePickerFormat()
        }
        calendarClassName={`custom-calendar ${className}`}
        dayClassName={(date: Date) => (date === startDate ? "selected-date" : "custom-date")}
        placeholderText={
          placeholder
            ? placeholder
            : showTimeInput
            ? dateUtils.getLocaleDateTimeFormat()?.toUpperCase()
            : dateUtils.getLocaleDateFormat()?.toUpperCase()
        }
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={showTimeInput}
        timeIntervals={15}
        timeInputLabel={timeInputLabel}
        isClearable={isClearable}
        showIcon={showIcon}
        icon={icon}
        calendarIconClassname={`pointer-events-none ${iconPosition === "right" ? "right-0" : ""}`}
        excludeTimes={excludeTimes}
        filterDate={filterDate}
        popperPlacement="bottom-start"
        customInput={
          showIconOnly ? (
            <button
              type="button"
              className="p-2 bg-transparent border-none cursor-pointer"
              disabled={disabled}
            >
              {icon}
            </button>
          ) : null
        }
      />
      {error && <div className="text-xs text-danger mt-1">{error}</div>}
    </div>
  );
};
interface CustomDateRangePickerProps {
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  startDate: any;
  endDate: any;
  handleDateChange: (dates: [Date | null, Date | null]) => void;
  label: string;
  placeholder?: string;
  showIcon?: boolean;
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  iconPosition?: "left" | "right" | undefined;
}
export const DateRangePicker = ({
  handleDateChange,
  startDate,
  endDate,
  disabled = false,
  minDate,
  maxDate,
  label,
  placeholder,
  showIcon = false,
  icon,
  iconPosition = "left",
}: CustomDateRangePickerProps) => {
  const [dates, setDates] = useState<[Date | null, Date | null]>([startDate, endDate]);
  const validYearRange = useMemo(
    () => (minDate ? years.filter((year) => minDate.getFullYear() <= year) : years),
    [minDate]
  );

  useEffect(() => {
    if (dates[0] !== startDate || dates[1] !== endDate) setDates([startDate, endDate]);
  }, [startDate, endDate]);

  const getValidMonthRange = (date: Date) => {
    if (minDate) {
      return minDate?.getFullYear() === date?.getFullYear()
        ? months.slice(minDate!.getMonth())
        : months;
    }
    return months;
  };

  return (
    <div>
      <div className="text-secondary-text text-xs mb-1 font-semibold whitespace-nowrap">
        {label}
      </div>
      <ReactDatePicker
        disabled={disabled}
        className={`block w-full px-3 !py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-strokes rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-holocene-blue focus:ring-0 sm:text-sm sm:leading-5 ${
          disabled ? "cursor-not-allowed" : ""
        }`}
        wrapperClassName={`block w-full ${showIcon ? "custom-icon-pos-" + iconPosition : ""}`}
        isClearable
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }: ReactDatePickerCustomHeaderProps) => (
          <div className="flex items-center">
            <button
              className="mr-7"
              onClick={(evt) => {
                evt.preventDefault();
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
            >
              <CaretLeft size={20} className="text-holocene-blue" weight="bold" />
            </button>

            <CustomDropdown
              data={getValidMonthRange(date)}
              onChange={(value: string) => {
                changeMonth(months.indexOf(value));
              }}
              classes="mr-3.5"
              defaultValue={months[getMonth(date)]}
              value={months[getMonth(date)]}
            />

            <CustomDropdown
              defaultValue={getYear(date)}
              data={validYearRange}
              onChange={(value: string) => changeYear(parseInt(value))}
              value={getYear(date)}
            />

            <button
              className="ml-7"
              onClick={(evt) => {
                evt.preventDefault();
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            >
              <CaretRight size={20} className="text-holocene-blue" weight="bold" />
            </button>
          </div>
        )}
        selected={dates[0]}
        onChange={setDates}
        onCalendarClose={() => handleDateChange(dates)}
        dateFormat={dateUtils.getDatePickerFormat()}
        calendarClassName="custom-calendar"
        dayClassName={(date: Date) => (date === dates[0] ? "selected-date" : "custom-date")}
        placeholderText={
          placeholder || `${dateUtils.getLocaleDateFormat()} - ${dateUtils.getLocaleDateFormat()}`
        }
        minDate={minDate}
        selectsRange
        startDate={dates[0] || undefined}
        endDate={dates[1]}
        showIcon={showIcon}
        icon={icon}
        onBlur={() => handleDateChange(dates)}
        calendarIconClassname={`pointer-events-none ${iconPosition === "right" ? "right-0" : ""}`}
      />
    </div>
  );
};

export default DatePicker;
