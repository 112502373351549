import React from "react";
import ReactTooltip, { Place, TooltipProps } from "react-tooltip";

const CustomTooltip = ({
  disabled,
  className,
  place = "top",
  ...rest
}: {
  disabled?: boolean;
  className?: string;
  place?: Place;
} & TooltipProps) => (
  <ReactTooltip
    className={
      "!bg-holocene-navy !rounded-md !pt-2 !pb-2.5 !px-4 max-w-[300px] after:!content-none " +
      (className || "")
    }
    disable={disabled}
    place={place}
    {...rest}
  />
);

export const rebuildTooltips = ReactTooltip.rebuild;
export const clearTooltips = ReactTooltip.hide;

export default CustomTooltip;
