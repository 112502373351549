import { Dialog, Transition } from "@headlessui/react";
import { X } from "phosphor-react";
import { Fragment, ReactNode } from "react";

export const ModalContainer = ({
  children,
  showModal,
  setShowModal,
  disableCloseOnOutsideClick,
  id,
  style,
  className,
  hideClose,
}: {
  children: ReactNode;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  disableCloseOnOutsideClick?: boolean;
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  hideClose?: boolean;
}) => {
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => !disableCloseOnOutsideClick && setShowModal(false)}
      >
        <div className="flex items-end justify-center min-h-screen h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              id={id}
              style={style}
              className={`relative pt-[18px] p-6 bg-white inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:align-middle sm:max-w-xl sm:w-full ${className}`}
            >
              {!hideClose && (
                <button
                  className="text-holocene-blue absolute top-5 right-3"
                  onClick={() => setShowModal(false)}
                >
                  <X size={18} />
                </button>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
